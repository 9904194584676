<template>
  <div id="all">
    <vue-element-loading
      background-color="rgba(255, 255, 255, .7)"
      :active="loading" 
      is-full-screen 
      spinner="line-scale"
      color="#00a4c9"
    />
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <modal name="realtime-record" modal-class="modal modal-lg" wrapper-class="modal-wrapper" :enable-close="false">
          <div class="modal-header">
            <h2 class="heading-inner">オンライン会議をご利用ですか？</h2>
          </div>
          <div class="modal-body realtime-record-modal-body">
            <li class="realtime-record-message">オンライン会議をご利用の際は会議が行われているタブを選択してください。</li>
              <li class="realtime-record-message">文字起こし方式再選択は<a class="realtime-record-reselect" @click="recordModalClose">こちら。</a></li>
          </div>
          <div class="modal-footer">
            <div class="btn_column">
              <button type="button" class="btn-display btn-primary" @click="GCPRecord()">
                <font-awesome-icon icon="comments" />
                <span class="record-btn">いいえ</span>
              </button>
              <button type="button" class="btn-display btn-primary" @click="dualRecord()">
                <font-awesome-icon icon="headset" />
                <span class="record-btn">はい</span>
              </button>
            </div>
          </div>
        </modal>
        <modal name="realtime-result" modal-class="modal modal-lg" wrapper-class="modal-wrapper" :enable-close="false">
          <div class="modal-header">
            <h2 class="heading-inner">文字起こし結果のファイル名</h2>
          </div>
          <div class="modal-body result-modal-body">
            <li v-if="resultShow === false" class="finished-message">文字起こしを終了しました。文字起こしファイル名を修正できます。</li>
            <li>ファイル名：{{ currentTextFileName }}</li>
            <input class="text-name-input" type="text" v-model="textFilename" placeholder="ファイル名を入力してください" />
            <p v-if="isInValidFileName" class="text-name-input-error">ファイル名に「/」は使用できません</p>
          </div>
          <div class="modal-footer">
            <div class="btn_column">
              <button type="button" class="btn-display btn-primary" v-on:click="modalClose">
                <i class="iconfont iconfont-reset" aria-hidden="true"></i>
                <span>キャンセル</span>
              </button>
              <button type="button" class="btn-display btn-primary"  :class="{ 'btn-loading': isInValidFileName }" @click="showResult">
                <font-awesome-icon icon="edit" />
                <span>名称変更</span>
              </button>
            </div>
          </div>
        </modal>
        <div v-show="resultShow === false">
          <div class="box form_column" v-if="(speechSituation === 0)">
            <div class="form_content">
              <dl class="form_list">
                <dt>
                  文字起こし方式選択
                </dt>
                <dd>
                  <div :class="{
                    form_column: true,
                    gray:
                    (speechSituation > 0)
                    | ((item_plan != 'premium') &
                    (item_plan != 'enterprise'))
                    | (remainingTime <= 0),
                    }">
                    <div class="form_group">
                      <input type="radio" name="whichAPI" value="0" v-model="whichAPI" id="one"
                        class="form_control-radio" />
                      <label for="one" class="form_control-label">
                        通常文字起こし
                      </label>
                    </div>
                    <div class="form_group">
                      <input type="radio" name="whichAPI" value="1" v-model="whichAPI" id="two"
                        class="form_control-radio" />
                      <label for="two" class="form_control-label">
                        補正文字起こし
                      </label>
                    </div>
                  </div>
                  <span v-if="((item_plan == 'premium') | (item_plan == 'enterprise')) & (remainingTime <= 0)">
                    補正文字起こしは利用可能時間が0秒なので選択できません
                  </span>
                  </dd>
                </dl>
              <dl class="form_list" v-if="whichAPI % 2">
                <dt>残り利用可能時間</dt>
                <dd>{{ displayUsageTime }}秒</dd>
              </dl>
            </div>
          </div>
          <div class="box" :class="{ gray: speechSituation > 1 }">
            <div class="heading-wrapper">
              <h2 class="heading-inner">文字起こし</h2>
            </div>
            <div class="result_scroll-content">
              <div class="result_display-contents">
                <pre v-if="timeRecord">{{ scriptTime }}</pre>
                <pre v-else>{{ script }}</pre>
              </div>
              <div class="result_display-contents">
                <pre v-if="speechCount % 2">{{ recognizingText }}...</pre>
              </div>
            </div>
            <div class="stopwatch">
              <p v-if="speechSituation" class="time">
                <span class="red-circle" :class="{ blinking: speechCount % 2 }"></span>
                文字起こし中 {{ min }}:{{ sec }}
              </p>
            </div>
          </div>
          <div class="btn_column" :class="{ blocked: speechSituation > 1 }">
            <button v-if="speechSituation == 0" @click="startRecordRealtime" type="button"
              class="btn-display btn-primary">
              <i class="iconfont iconfont-voice" aria-hidden="true"></i>
              <span>文字起こし開始</span>
            </button>
            <button v-else-if="speechCount % 2 == 0" @click="resumeRecordRealtime" type="button"
              class="btn-display btn-primary">
              <i class="iconfont iconfont-voice" aria-hidden="true"></i>
              <span>文字起こし再開</span>
            </button>
          <button v-else-if="speechCount % 2" @click="stopRecordRealtime" type="button" class="btn-display btn-primary">
              <font-awesome-icon class="font-awesome big" :icon="['far', 'pause-circle']" />
              <span>文字起こし停止</span>
            </button>

          <button v-if="speechSituation !== 0"  @click="finishRecordRealtime" type="button" class="btn-display btn-primary">
              <font-awesome-icon class="font-awesome big" :icon="['far', 'stop-circle']" />
              <span>文字起こし終了</span>
            </button>
            <div class="form_list">
              <dd>
                <div class="form_group">
                <input type="checkbox" name="time" v-model="timeRecordChecked" id="time" class="form_control-checkbox"/>
                  <label for="time" class="form_control-label">時間を記録する</label>
                <select  name="language" v-model="language" class="form_control-select" :disabled="speechSituation == 1">
                    <option selected value="ja-JP">日本語</option>
                    <option value="en-US">English(英語)</option>
                    <option value="zh-CN">普通话(中国語)</option>
                    <option value="zh-HK">粤語（香港）</option>
                    <option value="zh-TW">國語（台湾）</option>
                    <option value="ko-KR">한국의(韓国語)</option>
                    <option value="hi-IN">हिन्दी(ヒンディー語)</option>
                    <option value="es-ES">español(スペイン語)</option>
                    <option value="ru-RU">русский(ロシア語)</option>
                    <option value="pt-BR">português(ポルトガル語)</option>
                    <option value="fr-FR">français(フランス語)</option>
                    <option value="de-DE">Deutsch(ドイツ語)</option>
                  </select>
                </div>
              </dd>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store";

import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import MyHeader from "../globals/MyHeader.vue";
import MySidebar from "../globals/MySidebar.vue";

import StripeFunc from "../mixin/StripeFunc";
import UsageTime from "../mixin/usageTime";
import toggleFunctions from "../mixin/toggleFunctions";
import io from "socket.io-client";

export default {
  name: "RealtimeWithSpeechToText",
  mixins: [StripeFunc, toggleFunctions, UsageTime],
  components: {
    MyHeader,
    MySidebar,
  },
  data() {
    return {
      // remainingTime: 0,
      // whichAPI: 0, // API切り替えのフラグ。0:webSpeechAPI, 1:Google

      speechFinished: false, // 文字起こし終了のフラグ
      recognizingText: "",

      // タイマー関連
      timerId: null,
      GCPtimerId: null,
      startTime: Date.now(),
      elapsedTime: store.state.timerToadd2, // 経過時間

      // 録音関連
      language: "ja-JP",

      dlText: "",
      textFilename: "",
      currentTextFileName: "",
      resultShow: false,
      resultDisabled: true,
      downloadLink: null,
      playingAudio: false,

      // ユーザー情報
      user_id: firebase.auth().currentUser.uid,
      userIdToken: "",
      item_plan: "",

      userName: "",
      audioContext: null,
      ingestorIP: process.env.VUE_APP_API_ENDPOINT,
      reviewerIP: process.env.VUE_APP_API_ENDPOINT,
      nameRecordValue: false,
      timeRecordValue: true,
      playAudioValue: false,
      recordedUserList: [],
      canSave: true,
      loading: false,
      currentTime: 0,
      textnamePlusTime: '',
    };
  },
  async created() {
    this.resetSpeechSituation();
    // Google Chromeかどうか
    let userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("chrome") == -1) {
      alert(
        "お使いのブラウザは対応していません。Google Chromeをご利用ください。"
      );
    }
    // 録音できるかどうか
    if (navigator.mediaDevices) {
    } else {
      alert("mediaDevices is unavailable");
      // 「お使いのデバイスは録音に対応していません」と表示
    }

    // 利用時間の確認
    let userState = await this.getUsageTime();
    if (userState) {
      this.userType = userState.type;
      this.remainingTime = userState.time;
    }
    this.userIdToken = await firebase.auth().currentUser.getIdToken();
    this.item_plan = await this.getUserPlan();
  },
  beforeUnmount() {
    let self = this;
    // 文字起こし中であれば
    if (self.speechCount % 2) {
      self.updateTimerToadd(Date.now() - self.startTime);
      // 録音停止
      if (self.mediaRecorder) {
        self.pauseMediaRecorder();
      }
      self.addSpeechCount();
      // タイマー停止
      if (self.whichAPI == 0) {
        clearInterval(self.timerId);
      } else {
        clearInterval(self.timerId);
        clearInterval(self.GCPtimerId);
      }
      // 認識中だった文字起こしデータを完了データに移行
      if (self.recognizingText) {
        self.updateScript(self.recognizingText + "\n");
        self.updateScriptTime(
          "[" + self.min + ":" + self.sec + "] " + self.recognizingText + "\n"
        );
        self.pushRecordedText(self.recognizingText);
        self.pushRecordedTimer("[" + self.min + ":" + self.sec + "] ");
        if (self.recordedCount > 0) {
          self.pushRecordedStart(self.recordedEnd[self.recordedCount - 1]);
        } else {
          self.pushRecordedStart(0);
        }
        self.pushRecordedEnd(Number(self.min * 60) + Number(self.sec));
        self.addRecordedCount();
        self.recognizingText = "";
      }
    }
    self.updateDestroyed();
  
    if (self.socket_ingestor) {
      self.socket_ingestor.removeAllListeners();
      self.socket_ingestor.disconnect();
    }
    if (self.socket_reviewer) {
      self.socket_reviewer.removeAllListeners();
      self.socket_reviewer.disconnect();
    }
  },

  computed: {
    // 時間を計算する
    min() {
      let m = Math.floor(this.elapsedTime / 60000);
      return ("0" + m).slice(-2);
    },
    sec() {
      let s = Math.floor((this.elapsedTime % 60000) / 1000);
      return ("0" + s).slice(-2);
    },
    displayUsageTime() {
      let self = this;
      if ((self.whichAPI == 1) & (self.remainingTime <= 0)) {
        alert(
          "利用可能時間が0になりました。有料機能を使用するには追加課金をしてください。"
        );
        self.finishRecordRealtime();
      }
      return self.remainingTime;
    },
    whichAPI: {
      get() {
        return store.state.whichAPI;
      },
      set(val) {
        store.commit("setWhichAPI", val);
      },
    },
    remainingTime: {
      get() {
        return store.state.remainingTime;
      },
      set(val) {
        store.commit("setRemainingTime", val);
      },
    },
    nameRecordChecked: {
      get() {
        return store.state.nameRecord
      },
      set(val) {
        store.commit("SetNameRecord", val);
      }
    },
    timeRecordChecked: {
      get() {
        return store.state.timeRecord
      },
      set(val) {
        store.commit("SetTimeRecord", val);
      }
    },
    playAudioChecked: {
      get() {
        return store.state.playAudio
      },
      set(val) {
        store.commit("SetPlayAudio", val);
      }
    },
    isInValidFileName(){
      return this.textFilename.includes("/");
    },
    ...mapState([
      "speech",
      "script",
      "scriptTime",
      "speechSituation",
      "recordedCount",
      "blob",
      "recordedChunks",
      "recordedStart",
      "recordedEnd",
      "recordedTimer",
      "recordedText",
      "recordedUser",
      "timerToadd2",
      "mediaRecorder",
      "speechCount",
      "destroyed",
      "fileName",
      "recordedTime",
      "nameRecord",
      "timeRecord",
      "playAudio",
      "realname",
      "isRealtime",
    ]),
  },
  methods: {
    ...mapActions([
      "updateScript",
      "updateScriptTime",
      "resetSpeechSituation",
      "startSpeechSituation",
      "finishSpeechSituation",
      "pushRecordedText",
      "pushRecordedTimer",
      "pushRecordedUser",
      "pushRecordedStart",
      "pushRecordedEnd",
      "addRecordedCount",
      "updateBlob",
      "updateTimerToadd",
      "setMediaRecorder",
      "dataaddStartMediaRecorder",
      "dataaddResumeMediaRecorder",
      "startMediaRecorder",
      "resumeMediaRecorder",
      "pauseMediaRecorder",
      "stopMediaRecorder",
      "finishFunction",
      "nullifySpeech",
      "startSpeech",
      "setSpeech",
      "abortSpeech",
      "addSpeechCount",
      "finishSpeechCount",
      "updateDestroyed",
      "openDestroyed",
      "setFileName",
      "saveRecordedText",
      "setRecordedTime",
      "setRecordedUser",
      "setTimeRecord",
      "setNameRecord",
      "setPlayAudio",
      "setRealname",
      "setIsRealtime",
      "setWhichAPI",
    ]),
    // ユーザートークンの取得
    async renewUserIdToken() {
      this.userIdToken = await firebase.auth().currentUser.getIdToken();
    },
    // サイドバーの開け閉め
    toggleSidebar() {
      this.$refs.sidebar.toggleSide();
    },
    selectAPI() {
      this.updateWhichAPI();
    },
    startRecordRealtime() {
      if (this.whichAPI == 0) {
        this.webSpeechRecord();
      } else {
        this.recognizingModeSelect();
      }
    },
    // WebSpeechAPIの文字起こし
    webSpeechRecord() {
      let self = this;
      // var audio_th = document.getElementsByClassName("edit-wrapper-audio")[0];
      // 録音開始
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          self.setMediaRecorder(stream, {
            mimeType: "audio/webm",
          });
          self.dataaddStartMediaRecorder(self);
          // 文字起こし開始
          self.startMediaRecorder();
        })
        .catch(() => {
          alert("録音機能が利用できません。文字起こしは行われます。");
        });
      // タイマー開始
      self.startTime = Date.now();
      self.countUp();
      self.startSpeechSituation();
      self.addSpeechCount();
      self.pushRecordedTimer("[" + "00" + ":" + "00" + "] ");

      self.setSpeech(self);
      self.startSpeech();

      self.speech.onresult = (e) => {
        // 文字起こし実行中ならば
        if (self.speechCount % 2) {
          var results = e.results;
          self.recognizingText = "";
          for (var i = e.resultIndex; i < results.length; i++) {
            // 息継ぎ
            if (results[i].isFinal) {
              self.updateScript(results[i][0].transcript + "\n");
              self.updateScriptTime(
                "[" +
                self.min +
                ":" +
                self.sec +
                "] " +
                results[i][0].transcript +
                "\n"
              );
              self.pushRecordedText(results[i][0].transcript);
              self.pushRecordedTimer("[" + self.min + ":" + self.sec + "] ");
              if (self.recordedCount > 0) {
                self.pushRecordedStart(
                  Number(self.recordedEnd[self.recordedCount - 1])
                );
              } else {
                self.pushRecordedStart(0);
              }
              self.pushRecordedEnd(Number(self.min * 60) + Number(self.sec));
              self.addRecordedCount();
              self.recognizingText = "";
              // 発声中
            } else {
              let recognizing = results[i][0].transcript;
              self.recognizingText += recognizing;
              self.scrollToResult();
            }
          }
        }
      };
      // ちょっと止まるとonendが発火して文字起こしが止まるので再開させる
      self.speech.onend = () => {
        // 終了していたら再開させない
        if (!self.speechFinished) {
          self.startSpeech();
        }
      };
    },

    // GoogleAPIの文字起こし
    GCPRecord() {
      this.renewUserIdToken()
      let self = this;
      let audio_th = document.getElementsByClassName("edit-wrapper-audio")[0];

      // 音声データを、GCP内のreviewer.pyから受け取る。
      self.socket_reviewer = io(self.reviewerIP, {
        transports: ["websocket"],
        path: '/reviewer/socket.io/'
      });
      self.socket_reviewer.on("connect", () => {
        self.socket_reviewer.emit("create_instance", self.userIdToken);
      });

      // 録音開始
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          self.setMediaRecorder(stream, {
            mimeType: "video/webm;codecs=vp9",
          });
          self.dataaddStartMediaRecorder(self);
          // 文字起こし開始
          self.startMediaRecorder();
          // AudioContextで音声を取得
          self.audioContext = new AudioContext();
          let input = self.audioContext.createMediaStreamSource(stream);
          let processor = self.audioContext.createScriptProcessor(1024, 1, 1);
          input.connect(processor);
          processor.connect(self.audioContext.destination);
          // 音声データをingestor.pyへ送る。
          self.socket_ingestor = io(self.ingestorIP, {
            transports: ["websocket"],
            path: "/ingestor/socket.io/"
          });
          self.socket_ingestor.on("connect", () => {
            self.socket_ingestor.emit("create_instance", self.userIdToken, self.language);
          });
          processor.onaudioprocess = (e) => {
            // 議事録中だった場合、音声データを送る
            if (self.speechCount % 2 == 1) {
              let voice = e.inputBuffer.getChannelData(0);
              self.socket_ingestor.emit("data", {
                data: voice.buffer,
                user_id: self.user_id,
              });
            }
          };
        })
        .catch(() => {
          alert("録音機能が利用できません。文字起こしは行われます。");
        });
      // タイマー開始
      self.startTime = Date.now();
      self.countUp();
      self.startSpeechSituation();
      self.addSpeechCount();
      self.updateUsageTime();
      self.pushRecordedTimer("[" + "00" + ":" + "00" + "] ");
      // 文字起こし実行中ならば
      if (self.speechCount % 2 == 1) {
        self.recognizingText = "";
        let re = new RegExp("^\\[.+\\]");

        self.socket_reviewer.on("transcript" + self.user_id, (msg, cb) => {
          self.socket_reviewer.send("data_recieve");
          if (msg.length >= 7 && msg.substr(0, 7) == "[Final]") {
            let transcript = msg.substr(7, msg.length);
            self.updateScript(transcript + "\n");
            self.updateScriptTime(
              "[" + self.min + ":" + self.sec + "] " + transcript + "\n"
            );
            self.pushRecordedText(transcript);
            self.pushRecordedTimer("[" + self.min + ":" + self.sec + "] ");
            if (self.recordedCount > 0) {
              self.recordedStart.push(
                Number(self.recordedEnd[self.recordedCount - 1])
              );
            } else {
              self.recordedStart.push(0);
            }
            self.pushRecordedEnd(Number(self.min * 60) + Number(self.sec));
            self.addRecordedCount();
            self.recognizingText = "";
          } else {
            self.recognizingText = msg;
            self.scrollToResult();
          }
        });
      }
    },

    // 一時停止の解除
    resumeRecordRealtime() {
      let self = this;
      // 録音再開
      if (self.mediaRecorder) {
        self.resumeMediaRecorder();
        self.dataaddResumeMediaRecorder(self);
      }
      // 奇数にすることで文字起こし再開
      self.addSpeechCount();
      // タイマー再開
      self.startTime = Date.now();
      self.countUp();
      self.updateUsageTime();
      if ((self.whichAPI == 1) && !self.socket_ingestor.connected) {
        self.socket_ingestor.connect();
      }
      if ((self.whichAPI == 1) && !self.socket_reviewer.connected) {
        self.socket_reviewer.connect();
      }
    },
    // 録音の停止
    async stopRecordRealtime() {
      this.syncRemainingTime();
      let self = this;
      // 録音停止
      if (self.mediaRecorder) {
        self.pauseMediaRecorder();
      }
      if (self.socket_ingestor) {
        self.socket_ingestor.disconnect();
      }
      // 偶数にすることで文字起こし停止
      self.addSpeechCount();
      // タイマー停止
      if (self.webAPI == 0) {
        clearInterval(self.timerId);
      } else {
        clearInterval(self.timerId);
        clearInterval(self.GCPtimerId);
      }
      self.updateTimerToadd(Date.now() - self.startTime);
      // 認識中だった文字起こしデータを完了データに移行
      if (self.recognizingText) {
        self.updateScript(self.recognizingText + "\n");
        self.updateScriptTime(
          "[" + self.min + ":" + self.sec + "] " + self.recognizingText + "\n"
        );
        self.pushRecordedText(self.recognizingText);
        self.pushRecordedTimer("[" + self.min + ":" + self.sec + "] ");
        if (self.recordedCount > 0) {
          self.pushRecordedStart(self.recordedEnd[self.recordedCount - 1]);
        } else {
          self.pushRecordedStart(0);
        }
        self.pushRecordedEnd(Number(self.min * 60) + Number(self.sec));
        self.addRecordedCount();
        self.recognizingText = "";
      }
    },
    syncRemainingTime() {
      store.commit("setRemainingTime", store.state.remainingTime);
      this.remainingTime = store.state.remainingTime;
    },
    // 1秒(1000ミリ秒)を記録する
    countUp() {
      this.timerId = setInterval(() => {
        this.elapsedTime = Date.now() - this.startTime + this.timerToadd2;
      }, 1000);
    },
    // Google Speach APIの残り時間を測る
    updateUsageTime() {
      this.GCPtimerId = setInterval(() => {
        this.getUsageTime().then(userState => {
          if (this.remainingTime < userState.time - 60) {
            this.remainingTime = userState.time;
          } else {
            this.remainingTime -= 1;
          }
        });
      }, 1000);
    },
    
    // 音声ファイルをcloud storageに保存
    uploadAudioRealtime() {
      this.loading = true
      let self = this;
      self.$store.dispatch("updateBlob", self.recordedChunks);

      if (self.blob.size == 0) {
        setTimeout(() => {
          self.uploadAudioRealtime();
        }, 500);
      } else {
        let now = new Date();
        let year = now.getFullYear();
        let month = ("0" + (now.getMonth() + 1)).slice(-2);
        let day = ("0" + now.getDate()).slice(-2);
        let hour = ("0" + now.getHours()).slice(-2);
        let minute = ("0" + now.getMinutes()).slice(-2);
        let second = ("0" + now.getSeconds()).slice(-2);
        let time = year + month + day + hour + minute + second;

        this.textFilename = time;
        var filenamePlusTime = this.textFilename  + "-" + time + ".webm";
        let textnamePlusTime =  this.textFilename  + "-" + time + ".txt";
        let resultText = "";
        let lines = [];
        for (let count = 0; count < self.recordedCount; count++) {
          let line = '';

          line += self.recordedTimer[count];

          if (self.recordedUser[count] === undefined) {
            line += "会議参加者: ";
          } else {
            line += self.recordedUser[count] + ": ";
          }

          line += self.recordedText[count];

          lines.push(line + "\n");
        }
        resultText = lines.join('');
        
        var formData = new FormData();
        formData.append("user_id", self.user_id);
        formData.append("text_name", textnamePlusTime);
        formData.append("text_result", resultText);
        formData.append("time_display", this.timeRecord);
        formData.append("participants_display", this.nameRecord);
        const headers = { Authorization: "Bearer " + self.userIdToken };
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/save_realtime",
            formData,
            { headers: headers }
          )
          .then((response) => { })
          .catch((error) => { })
          .finally(() => {
            this.setRecordedTime(time);
            this.setFileName(time);
            this.loading = false;
            this.resultModal();
            this.setIsRealtime(true);
            this.setWhichAPI(this.whichAPI);
            this.setRealname(textnamePlusTime);
          });
        // 音声ファイルをcloud storageに保存
        let storage = firebase.app().storage(process.env.VUE_APP_STORAGE_PATH);
        let dataRef = storage.ref(`/${self.user_id}/realtime/${filenamePlusTime}`);
        let uploadTask = dataRef.put(self.blob);
        uploadTask.on(
          "state_changed",
          function(snapshot) {},
          function(error) {},
          function() {
            this.loading = false;
          }
        );
      }
    },
    // 議事録終了を押したときの挙動
    async finishRecordRealtime() {
      await this.stopRecordRealtime();
      this.loading = true;
      let self = this;

      // 録音停止
      if (self.mediaRecorder) {
        self.stopMediaRecorder();
        self.finishFunction(self);
        self.mediaRecorder.stream.getTracks().forEach(
          track => track.stop()
        );
      }

      self.uploadAudioRealtime();

      // 文字起こし終了
      self.finishSpeechCount();
      self.finishSpeechSituation();
      if (self.speechSituation == 1) {
        self.abortSpeech();
      }
      self.speechFinished = true;
      self.nullifySpeech();
      
      if (self.socket_ingestor) {
        self.socket_ingestor.removeAllListeners();
        self.socket_ingestor.disconnect();
      }
      if (self.socket_reviewer) {
        self.socket_reviewer.removeAllListeners();
        self.socket_reviewer.disconnect();
      }

      // タイマー停止
      clearInterval(self.timerId);
      clearInterval(self.GCPtimerId);
      self.updateTimerToadd(Date.now() - self.startTime);

      // 認識中だった文字起こしデータを完了データに移行
      if (self.recognizingText) {
        self.updateScript(self.recognizingText);
        self.updateScriptTime(
          "[" + self.min + ":" + self.sec + "] " + self.recognizingText
        );
        self.recognizingText = "";
      }
      this.loading = false;
    },
    async editTextName() {
      this.renewUserIdToken();
      const params = {
        user_id: this.user_id,
        old_name: this.currentTextFileName + "-" + this.recordedTime,
        new_name: this.textFilename + "-" + this.recordedTime,
      };
      const headers = { Authorization: "Bearer " + this.userIdToken };
      this.loading = true
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/edit_filename",
          params,
          { headers: headers }
        )
        .then((response) => {
          if (response.status === 200) {
            this.currentTextFileName = this.textFilename;
            this.setFileName(this.currentTextFileName);
            this.setRealname(this.textFilename + "-" + this.recordedTime + ".txt");
            this.$router.push("/edit_result");
          }
        }).finally(
          this.loading = true
        );
    },
    resultModal() {
      if (this.currentTextFileName !== "") {
        this.textFilename = this.currentTextFileName;
      } else {
        this.currentTextFileName = this.textFilename;
      }
      this.$modal.show('realtime-result');
    },
    modalClose() {
      this.textFilename = this.currentTextFileName;
      this.resultShow = true;
      this.$modal.hide('realtime-result');
      this.$router.push("/edit_result");
    },
    async showResult() {
      if(this.textFilename === "") {
        this.textFilename = this.recordedTime;
      }
      await this.editTextName();
      this.resultShow = true;
      this.$modal.hide('realtime-result');
    },
    inputTranscription() {
      let self = this;

      // 自己の音声を文字起こしをする
      self.setSpeech(self);
      self.startSpeech();
      if (firebase.auth().currentUser.displayName && firebase.auth().currentUser.displayName !== "") {
        self.userName = firebase.auth().currentUser.displayName;
      } else {
        self.userName = "TEXTAのユーザー";
      }
      self.speech.onresult = (e) => {
        // 文字起こし実行中ならば
        if (self.speechCount % 2) {
          var results = e.results;
          self.recognizingText = "";
          for (var i = e.resultIndex; i < results.length; i++) {
            // 息継ぎ
            if (results[i].isFinal) {
              let transcriptResult = "";
              if (self.nameRecord) {
                transcriptResult = self.userName + ":" + results[i][0].transcript;
              } else {
                transcriptResult = results[i][0].transcript;
              }
              self.updateScript(transcriptResult + "\n");
              self.updateScriptTime(
                "[" + self.min + ":" + self.sec + "] " + transcriptResult + "\n"
              );
              self.pushRecordedText(results[i][0].transcript);
              self.pushRecordedUser(self.userName);
              self.pushRecordedTimer("[" + self.min + ":" + self.sec + "] ");
              if (self.recordedCount > 0) {
                self.pushRecordedStart(
                  Number(self.recordedEnd[self.recordedCount - 1])
                );
              } else {
                self.pushRecordedStart(0);
              }
              self.pushRecordedEnd(Number(self.min * 60) + Number(self.sec));
              self.addRecordedCount();
              self.recognizingText = "";
              // 発声中
            } else {
              let recognizing = results[i][0].transcript;
              if (self.nameRecord) {
                self.recognizingText += self.userName + ":" + recognizing;
              } else {
                self.recognizingText += recognizing;
              }
            }
            self.scrollToResult();
          }
        }
      };
      // ちょっと止まるとonendが発火して文字起こしが止まるので再開させる
      self.speech.onend = () => {
        // 終了していたら再開させない
        if (!self.speechFinished) {
          self.startSpeech();
        }
      };
    },
    async outputTranscription() {
      let self = this;

      // 出力端末の音声を文字起こしをする
      self.audioContext = new AudioContext();
      let displayAudioTrack = self.displayStream.getAudioTracks()[0];
      let input = self.audioContext.createMediaStreamSource(self.displayStream);
      let processor = self.audioContext.createScriptProcessor(1024, 1, 1);
      input.connect(processor);
      processor.connect(self.audioContext.destination);
      self.socket_ingestor = io(self.ingestorIP, {
        transports: ["websocket"],
        path: "/ingestor/socket.io/"
      });
      self.socket_ingestor.on("connect", () => {
        self.socket_ingestor.emit("create_instance", self.userIdToken, self.language);
      });
      processor.onaudioprocess = (e) => {
        if (self.speechCount % 2 == 1) {
          let voice = e.inputBuffer.getChannelData(0);
          self.socket_ingestor.emit("data", {
            data: voice.buffer,
            user_id: self.user_id,
          });
        }
      };

      // 文字起こし実行中ならば
      if (self.speechCount % 2 == 1) {
        self.recognizingText = "";
        let re = new RegExp("^\\[.+\\]");
        self.socket_reviewer.on("transcript" + self.user_id, (msg, cb) => {
          self.socket_reviewer.send("data_recieve");
          if (msg.length >= 7 && msg.substr(0, 7) == "[Final]") {
            let transcript = "";
            if (self.nameRecord) {
              transcript = "会議参加者：" + msg.substr(7, msg.length);
            } else {
              transcript = msg.substr(7, msg.length);
            }
            self.updateScript(transcript + "\n");
            self.updateScriptTime(
              "[" + self.min + ":" + self.sec + "] " + transcript + "\n"
            );
            self.pushRecordedText(msg.substr(7, msg.length));
            self.pushRecordedUser("会議参加者");
            self.pushRecordedTimer("[" + self.min + ":" + self.sec + "] ");
            if (self.recordedCount > 0) {
              self.recordedStart.push(
                Number(self.recordedEnd[self.recordedCount - 1])
              );
            } else {
              self.recordedStart.push(0);
            }
            self.pushRecordedEnd(Number(self.min * 60) + Number(self.sec));
            self.addRecordedCount();
            self.recognizingText = "";
          } else {
            if (self.nameRecord) {
              self.recognizingText = "会議参加者：" + msg;
            } else {
              self.recognizingText = msg;
            }
            self.scrollToResult();
          }
        });
      }

      // タブ共有が停止すれば
      displayAudioTrack.onended = () => {
        this.stopRecordRealtime();
        this.finishRecordRealtime();
      };
    },
    recognizingModeSelect() {
      this.$modal.show('realtime-record');
    },
    recordModalClose(){
      this.$modal.hide('realtime-record');
    },
    async dualRecord() {
      await this.renewUserIdToken()
      let self = this;
      this.setNameRecord(true);
      // 音声データを、GCP内のreviewer.pyから受け取る。
      self.socket_reviewer = io(self.reviewerIP, {
        transports: ["websocket"],
        path: '/reviewer/socket.io/'
      });
      self.socket_reviewer.on("connect", () => {
        self.socket_reviewer.emit("create_instance", self.userIdToken);
      });

      // 新ストリーム作成
      try{
        const dualContext = new AudioContext();
        self.displayStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
        self.userStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

        // 画像データーを取得しない様にする
        self.displayStream.getVideoTracks()[0].stop();
        self.displayStream.removeTrack(self.displayStream.getVideoTracks()[0]);

        // チャンネルを結語
        let outputChannel = dualContext.createMediaStreamSource(self.displayStream);
        let inputChannel = dualContext.createMediaStreamSource(self.userStream);
        self.streamDestination = dualContext.createMediaStreamDestination();
        outputChannel.connect(self.streamDestination);
        inputChannel.connect(self.streamDestination);
        let stream = self.streamDestination.stream;

        // 録音開始
        self.setMediaRecorder(stream, {
          mimeType: "audio/webm;",
        });
        self.dataaddStartMediaRecorder(self);
        self.startMediaRecorder();

        // タイマー開始
        self.startTime = Date.now();
        self.countUp();
        self.startSpeechSituation();
        self.addSpeechCount();
        self.updateUsageTime();
        self.outputTranscription();
        self.inputTranscription();
        self.pushRecordedTimer("[" + "00" + ":" + "00" + "] ");

      } catch {
        alert("録音機能が利用できません。オーディオデバイスを確認してください");
      }
    },
    isScrollBottom(container) {
      return container.scrollTop + container.clientHeight >= container.scrollHeight * 0.9;
    },
    scrollToResult() {
      this.$nextTick(()=>{
        const container = this.$el.querySelector(".result_scroll-content");
        if(container && this.isScrollBottom(container)) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth"
          });
        }
      })
    },
  },
  watch: {
    'mediaRecorder.state'(newState) {
      if (newState === 'recording') {
        this.$modal.hide('realtime-record');
      }
    },
    async resultShow(newVal) {
      if (newVal === true) {
        try {
          await this.displayStream.getTracks().forEach(track => track.stop());
          this.displayStream = null;
          await this.userStream.getTracks().forEach(track => track.stop());
          this.userStream = null;
        } catch {
          return true;
        }
      }
    },
    // 録音中のトークンを取得
    async min(newVal) {
      if (newVal) {
        await this.renewUserIdToken();
      }
    }
  },
};
</script>

<style scoped>
.time {
  display: inline-block;
  color: red;
}

.red-circle {
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 1.4rem;
  background-color: red;
  line-height: 1.4rem;
  opacity: 0;
}

.blinking {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}

.stopwatch {
  text-align: left;
}

table {
  text-align: left;
  width: 100%;
}

td:nth-child(1) {
  font-weight: bold;
  width: 12rem;
}

td:nth-child(2) .form_group {
  margin-top: 0.1rem;
}

.edit-wrapper td:nth-child(1) {
  vertical-align: -1rem;
}

.result_scroll-content {
  max-height: 45vh;
  overflow-y: auto;
}

.filename-wrapper input {
  width: 30rem;
  border: 0.1rem solid gray;
  border-radius: 0.2rem;
  padding: 0 1rem;
  margin-right: 0.6rem;
}

.nonselected {
  background-image: linear-gradient(#ccc 0%, #ccc 100%);
}

/* ========================= */
/* =   以下クラウドワークス   = */
/* ========================= */

.form_column {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

.form_content {
  text-align: left;
  -webkit-box-flex: 1;
  flex: 1;
}

.form_list dt {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 15px;
  font-size: 1.5rem;
}

.is-disabled .form_list dt {
  opacity: 0.5;
}

.form_list dd {
  font-size: 13px;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.form_list+.form_list {
  margin-top: 3rem;
}

.form_group {
  display: inline-block;
  margin: 0.7rem 0;
  position: relative;
}

.checkbox_group {
  display: flex;
  column-gap: 3rem;
  margin-left: 2rem;
}

.form_column .form_group {
  margin-right: 3rem;
}

.form_control,
.form_control-radio,
.form_control-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.form_control-select {
  display: block;
  position: relative;
  padding-left: calc(1.8rem + 1rem);
  border-style: solid;
  background-image: linear-gradient(90deg,
      #00a4c9 0%,
      #00a4c9 50%,
      #4067a3 100%);
  /* background-color: #00a4c9; */
  color: white;
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 10px;
}

.form_control-select option {
  color: black;
  background: white;
}

.form_control-label {
  display: block;
  position: relative;
  padding-left: calc(1.8rem + 1rem);
  cursor: pointer;
}

.form_control-label::before,
.form_control-label::after {
  content: "";
  position: absolute;
  z-index: 1;
}

.form_control-label::before {
  top: 0.4rem;
  left: 0;
  width: 1.8rem;
  height: 1.8rem;
  border: 0.1rem solid #dcdcdc;
  background-color: #f6f6f6;
}

:disabled+.form_control-label {
  cursor: not-allowed;
  opacity: 0.5;
}

:disabled+.form_control-label:hover {
  opacity: 0.5;
}

.form_control-label:hover {
  opacity: 0.8;
}

.form_control-radio+.form_control-label::before,
.form_control-radio+.form_control-label::after {
  border-radius: 100%;
}

.form_control-checkbox+.form_control-label::before,
.form_control-checkbox+.form_control-label::after {
  border-radius: 0;
}

.form_control-radio:checked+.form_control-label::before,
.form_control-checkbox:checked+.form_control-label::before {
  border-color: #00a4c9;
  background-color: #fff;
}

.form_control-radio:checked+.form_control-label::after,
.form_control-checkbox:checked+.form_control-label::after {
  top: 0.8rem;
  left: 0.4rem;
  width: 1rem;
  height: 1rem;
  background-color: #00a4c9;
}

.blocked .form_control-radio:checked+.form_control-label::after,
.blocked .form_control-checkbox:checked+.form_control-label::after {
  top: 0.7rem;
}
.result-modal-body {
  padding: 2rem;
  text-align: left !important;
  .finished-message {
    margin-bottom: 2rem;
  }
  .text-name-input {
    border-style: solid;
    width: 100%;
  }
  .text-name-input-error {
    color: red;
  }
}
.realtime-record-modal-body {
  padding: 2rem;
  text-align: left !important;
  .realtime-record-message {
    &:last-child {
      margin-bottom: 1rem;
    }

    & .realtime-record-reselect {
      cursor: pointer;
    }
  }
}
.record-btn {
  margin-left: 0.5rem;
}
.modal-footer {
  padding-bottom: 2rem;
}
.text-right {
  text-align: right;
}
.btn-blocked {
  background-image: linear-gradient(#ccc,#ccc);
  box-shadow: none;
  border: 1px solid #ddd;
  color: #fff
}
.main {
  padding: 1rem 6rem 5.0rem 6rem;
  -webkit-box-flex: 1;
  flex: 1;
  position: relative;
}
.heading-wrapper {
  margin: -3rem -3rem 1.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}
.box+.btn_column {
  margin-top: 1.5rem;
}
</style>
