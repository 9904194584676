<template>
  <div id="all">
    <MyHeader @sidebar="toggleSidebar" />
    <div class="container">
      <MySidebar ref="sidebar" />
      <main class="main">
        <div class="box">
          <div class="heading-wrapper">
            <h2 class="heading-inner">アカウント情報</h2>
          </div>
          <div class="contents">
            <div class="left_column">
              <p>アカウント名</p>
              <br />
              <p>メールアドレス</p>
              <br />
              <p>パスワード</p>
              <br />
              <!-- <p class="verify" @click="deleteAccount">アカウントを削除</p><br> -->
            </div>
            <div class="center_column">
              <p>{{ currentUser.displayName }}</p>
              <br />
              <p>{{ currentUser.email }}</p>
              <br />
              <p>********</p>
              <br />
            </div>
            <div class="right_column">
              <router-link to="/editname">アカウント名を変更</router-link>
              <br />
              <p
                v-if="!currentUser.emailVerified"
                @click="resendEmail"
                class="verify"
              >
                メールアドレスを認証
              </p>
              <router-link to="/editemail">メールアドレスを変更</router-link>
              <br />
              <router-link to="/editpassword">パスワードを変更</router-link>
            </div>
          </div>
          <div class="heading-wrapper" v-if="contract.status !== 'enterprise'">
            <h2 class="heading-inner">契約内容</h2>
          </div>
          <div v-if="contract.status !== 'enterprise'">
            <vue-element-loading
              background-color="rgba(255, 255, 255, .7)"
              :active="is_loading" 
              is-full-screen 
              spinner="line-scale"
              color="#00a4c9"
            />
            <div v-if="!is_loading" class="contents">
              <div class="left_column">
                <p>現在のプラン</p>
                <br />
                <p>請求先クレジットカード</p>
                <br />
                <p>次回のご請求日</p>
                <br />
                <p>次回のご請求額(暫定)</p>
                <br />
                <p>残り利用時間</p>
              </div>
              <div class="center_column">
                <p>{{ contract.plan }}</p>
                <br />
                <p>{{ contract.card }}</p>
                <br />
                <p>{{ contract.next }}</p>
                <br />
                <p>{{ contract.billing }}円</p>
                <br />
                <p>{{ remainingTime }}秒</p>
              </div>

              <div v-bind:class="[isRightColumn ? 'right_column' : '']">
                <!-- 機能制限のためコメントアウトする必要あり -->
                <!-- ここから... -->
                <div v-if="contract.status !== 'trial'">
                  <router-link
                    v-if="isStripeRequestPlan"
                    :to="{
                      name: 'StripeChangePlan'
                    }"
                    >プランの申込</router-link
                  >
                  <router-link
                    v-else-if="
                      isStripeChangePlan
                    "
                    :to="{
                      name: 'StripeChangePlan'
                    }"
                    >プランの変更</router-link
                  >
                  <br />
                  <router-link
                    v-if="isStripeChangeCard"
                    :to="{
                      name: 'StripeChangeCard'
                    }"
                    >支払い情報の変更</router-link
                  >
                  <br />
                  <p>-----</p>
                  <br />
                  <p>-----</p>
                  <br />
                  <router-link
                    v-if="
                      isStripeAddUsageTime
                    "
                    :to="{
                      name: 'StripeAddUsageTime'
                    }"
                    >利用時間の追加</router-link
                  >
                </div>
                <!-- ...ここまで -->
              </div>
            </div>
          </div>
        </div>
        <div class="withdrawal">
          <withdrawal
          :stripe_fs_info = stripe_fs_info
          ></withdrawal>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// official
import firebase from "firebase/app";
import { loadStripe } from "@stripe/stripe-js";

// modules
import MyHeader from "@/components/globals/MyHeader.vue";
import MySidebar from "@/components/globals/MySidebar.vue";
import Withdrawal from "@/components/modules/Withdrawal.vue";

// mixin
import UsageTime from "../mixin/usageTime";
import StripeFunc from "@/components/mixin/StripeFunc.js";
import ArrangeDate from "@/components/mixin/ArrangeDate.js";
import BaseFunctions from "@/components/mixin/BaseFunctions.js";

export default {
  mixins: [ArrangeDate, StripeFunc, BaseFunctions, UsageTime],
  name: "Account",
  components: {
    MyHeader,
    MySidebar,
    Withdrawal,
  },
  data() {
    return {
      // base
      currentUser: firebase.auth().currentUser,

      // 判定
      is_loading: false,

      // stripe
      stripe_fs_info: {},
      contract: {},

      // 利用時間
      remainingTime: 0,
    };
  },
  async created() {
    const self = this;
    self.is_loading = true;

    // 残り利用時間の取得
    let userState = await self.getUsageTime();
    if (!userState) {
      await self.createUsageTime();
      userState = await self.getUsageTime();
    }
    self.remainingTime = userState.time;

    // 1. FSの情報(stripeに登録しているかどうか)
    let stripe_fs_info = await self.getFSinfo(self.currentUser.uid);
    // カードの登録あり
    self.stripe_fs_info = stripe_fs_info;
    // 2. 会員区分
    let contract = await self.getContract(
      self.currentUser,
      self.stripe_fs_info
    );
    self.contract = contract;
    self.is_loading = false;
  },
  methods: {
    // 認証メール再送
    resendEmail: function() {
      this.currentUser
        .sendEmailVerification()
        .then(() => {
          alert("認証メールを再送しました", this.$store.getters.user.email);
        })
        .catch((error) => {
          if (error.code == "auth/too-many-requests") {
            alert(
              "短時間に送信できるリクエストの上限を超えました。\n時間をおいてもう一度お試しください。"
            );
          }
          alert("確認メールが送れませんでした", error);
        });
    },

    // アカウントの削除
    deleteAccount: function() {
      var user = firebase.auth().currentUser;
      var res = confirm("アカウントを削除します。本当によろしいですか？");
      if (res == true) {
        user
          .delete()
          .then(function() {
            // User deleted.
            alert("アカウントを削除しました");
          })
          .catch(function(error) {
            // An error happened.
            alert("アカウントを削除できませんでした");
          });
      }
    },
  },
  computed:{
      isRightColumn() {
      // メールアドレス未認証
      if(this.contract.status === 'mail-invalid' || !this.currentUser.emailVerified) {
        return false;
      }
      return this.contract.plan !== 'enterprise';
    },

    isStripeRequestPlan() {
      // メールアドレス未認証
      if(this.contract.status === 'mail-invalid' || !this.currentUser.emailVerified) {
        return false;
      }
      return this.contract.status == 'free';
    },

    // プランの変更
    isStripeChangePlan() {
      // メールアドレス未認証
      if(this.contract.status === 'mail-invalid' || !this.currentUser.emailVerified) {
        return false;
      }
      // 無料会員 with stripe
      if(this.contract.status === 'free' && this.contract.card !=='未登録') {
        return false;
      }
      return this.contract.status === 'continue' || this.contract.status === 'canceled';
    },

    // 支払い情報の変更
    isStripeChangeCard() {
      if(this.contract.status === 'mail-invalid' || !this.currentUser.emailVerified) {
        return false;
      }
      return this.contract.card !== '未登録';
    },

    // 利用時間の追加
    isStripeAddUsageTime() {
      if(this.contract.status === 'mail-invalid' || !this.currentUser.emailVerified) {
        return false;
      }
      return this.contract.status === 'continue' || this.contract.status === 'canceled';
    },
  },
};
</script>

<style scoped>
p,
a {
  display: inline-block;
  margin: 12px 0;
}
p a {
  margin: 0;
}

div + .heading-wrapper {
  margin: 3rem -3rem 2.5rem;
}

.contents {
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
  margin: 0 auto;
}
.contents div {
  text-align: left;
  min-width: 30rem;
}
.right_column {
  color: #00a4c9;
  cursor: pointer;
}
.verify {
  color: #ff3322;
  margin-right: 2rem;
  cursor: pointer;
}
.withdrawal {
  margin-top: 20px;
  text-align: right;
}
</style>
