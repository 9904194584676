import { createStore } from 'vuex'
import io from "socket.io-client";


const store = createStore({
  state: {
    user: {},
    status: false,
    trial: false,
    remainingTime: "",
    alertConfirmation: false,
    // ステータス
    speechSituation: 0, // 0:before, 1:start, 2:finish
    speech: null,
    speechCount: 0, // 奇数なら実行中、偶数なら停止中
    destroyed: false, // ページ遷移をしたか
    // タイマー関連
    timerToadd2: 0,
    // 録音関連
    mediaRecorder: null,
    blob: null,
    script: "",
    scriptTime: "",
    recordedCount: 0,
    recordedStart: [],
    recordedEnd: [],
    recordedTimer: [],
    recordedText: [],
    recordedChunks: [],
    recordedUser: [],
    // API選択
    remainingTime: 0,
    whichAPI: 0,
    // 文字起こし関連
    socket_ingestor: null,
    socket_reviewer: null,
    fileName:"",
    recordedTime:"",
    nameRecord: false,
    timeRecord: true, // 時間を表示するかどうか
    isRealtime: false,
  },
  mutations: {
    SetSocketIngestor(state, IP) {
      state.socket_ingestor = io(IP, {
        transports: ["websocket"],
      });
    },
    SetSocketReviewer(state, IP) {
      state.socket_reviewer = io(IP, {
        transports: ["websocket"],
      });
    },

    onAuthStateChanged(state, user) {
      state.user = user; // ユーザー情報
    },
    changeVerified(state, user) {
      state.user.emailVerified = user.emailVerified;
    },
    confirmAlert(state) {
      state.alertConfirmation = true;
    },
    setScript(state, val) {
      state.script += val;
    },
    setScriptTime(state, val) {
      state.scriptTime += val;
    },
    setTimerToadd(state, val) {
      state.timerToadd2 += val;
    },
    setWhichAPI(state) {
      state.whichAPI = (state.whichAPI + 1) % 2;
    },
    setWhichAPI(state, val) {
      state.whichAPI = val;
    },
    setRemainingTime(state, val) {
      state.remainingTime = val;
    },
    ResetSpeechSituation(state) {
      state.user = {};
      state.status = false;
      state.trial = false;
      state.remainingTime = "";
      state.alertConfirmation = false;
      state.speechSituation = 0;
      state.speech = null;
      state.speechCount = 0;
      state.destroyed = false;
      state.timerToadd2 = 0;
      state.mediaRecorder = null;
      state.blob = null;
      state.script = "";
      state.scriptTime = "";
      state.recordedCount = 0;
      state.recordedStart = [];
      state.recordedEnd = [];
      state.recordedTimer = [];
      state.recordedText = [];
      state.recordedChunks = [];
      state.recordedUser = [];
      state.remainingTime = 0;
      state.whichAPI = 0;
      state.socket_ingestor = null;
      state.socket_reviewer = null;
      state.fileName = "";
      state.recordedTime = "";
      state.nameRecord = false;
      state.timeRecord = true;
      state.isRealtime = false;
    },
    StartSpeechSituation(state) {
      state.speechSituation = 1;
    },
    FinishSpeechSituation(state) {
      state.speechSituation = 2;
    },
    PushRecordedText(state, val) {
      state.recordedText.push(val);
    },
    PushRecordedTimer(state, val) {
      state.recordedTimer.push(val);
    },
    SetRecordedTimer(state, val) {
      state.recordedTimer = val;
    },
    PushRecordedUser(state, val) {
      state.recordedUser.push(val);
    },
    PushRecordedStart(state, val) {
      state.recordedStart.push(val);
    },
    SetRecordedStart(state, val) {
      state.recordedStart = val;
    },
    PushRecordedEnd(state, val) {
      state.recordedEnd.push(val);
    },
    SetRecordedEnd(state, val) {
      state.recordedEnd = val;
    },
    AddRecordedCount(state) {
      state.recordedCount += 1;
    },
    SetRecordedCount(state, val) {
      state.recordedCount = val;
    },
    setBlob(state, val) {
      state.blob = new Blob(val);
    },
    SetMediaRecorder(state, val1, val2) {
      state.mediaRecorder = new MediaRecorder(val1, val2);
    },
    // 録音を開始した時の処理
    DataaddStartMediaRecorder(state, self) {
      state.mediaRecorder.addEventListener("dataavailable", function(e) {
        if (e.data.size > 0) {
          state.recordedChunks.push(e.data);
        }
      });
    },
    // 録音を再開した時の処理
    DataaddResumeMediaRecorder(state, self) {
      state.mediaRecorder.addEventListener("dataavailable", function(e) {
        if (e.data.size > 0) {
          state.recordedChunks.push(e.data);
        }
      });
    },
    // 録音を終了した時の処理
    // 音声を保存する
    FinishFunction(state, self) {
      state.blob = new Blob(state.recordedChunks);
      state.mediaRecorder.ondataavailable = function(e) {
        self.$store.dispatch("updateBlob", state.recordedChunks);
      };
    },
    // 録音を開始する
    StartMediaRecorder(state) {
      state.mediaRecorder.start();
    },
    // 録音を再開する
    ResumeMediaRecorder(state) {
      state.mediaRecorder.resume();
    },
    // 録音を停止する
    PauseMediaRecorder(state) {
      state.mediaRecorder.pause();
    },
    // 録音を終了する
    StopMediaRecorder(state) {
      state.mediaRecorder.stop();
    },
    // Web Speech APIのオブジェクトを初期化する
    NullifySpeech(state) {
      state.speech = null;
    },
    // Web Speech APIのオブジェクトを作成
    SetSpeech(state, self) {
      // Web Speech API
      state.speech = new webkitSpeechRecognition();
      state.speech.lang = self.language;
      state.speech.interimResults = true; // リアルタイム文字起こし用
    },
    // 文字起こしを開始する
    StartSpeech(state) {
      state.speech.start();
    },
    // 文字起こしを中断する
    AbortSpeech(state) {
      state.speech.abort();
    },
    AddSpeechCount(state) {
      state.speechCount += 1;
    },
    FinishSpeechCount(state) {
      state.speechCount = 10000;
    },
    setDestroyed(state) {
      state.destroyed = true;
    },
    OpenDestroyed(state) {
      state.destroyed = false;
    },
    SetFileName(state, val) {
      state.fileName = val
    },
    SaveRecordedText(state, val) {
      state.recordedText = val;
    },
    SetRecordedTime(state, val) {
      state.recordedTime = val
    },
    SetRecordedUser(state, val) {
      state.recordedUser = val
    },
    SetTimeRecord(state, val) {
      state.timeRecord = val
    },
    SetNameRecord(state, val) {
      state.nameRecord = val
    },
    SetPlayAudio(state, val) {
      state.playAudio = val
    },
    SetRealname(state, val) {
      state.realname = val
    },
    SetIsRealtime(state, val) {
      state.isRealtime = val
    },
    SetSelectFile(state, val) {
      state.selectFile = val
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
    alertConfirmation(state) {
      return state.alertConfirmation;
    },
  },
  actions: {
    setSocketIngestor({ commit }, val) {
      commit("SetSocketIngestor", val);
    },
    setSocketReviewer({ commit }, val) {
      commit("SetSocketReviewer", val);
    },
    updateScript({ commit }, val) {
      commit("setScript", val);
    },
    updateScriptTime({ commit }, val) {
      commit("setScriptTime", val);
    },
    updateTimerToadd({ commit }, val) {
      commit("setTimerToadd", val);
    },
    updateWhichAPI({ commit }) {
      commit("setWhichAPI");
    },
    setWhichAPI({ commit }, val) {
      commit("setWhichAPI", val);
    },
    updateRemainingTime({ commit }, val) {
      commit("setRemainingTime", val);
    },
    resetSpeechSituation({ commit }) {
      commit("ResetSpeechSituation");
    },
    startSpeechSituation({ commit }) {
      commit("StartSpeechSituation");
    },
    finishSpeechSituation({ commit }) {
      commit("FinishSpeechSituation");
    },
    pushRecordedText({ commit }, val) {
      commit("PushRecordedText", val);
    },
    pushRecordedTimer({ commit }, val) {
      commit("PushRecordedTimer", val);
    },
    setRecordedTimer({ commit }, val) {
      commit("SetRecordedTimer", val);
    },
    pushRecordedUser({ commit }, val) {
      commit("PushRecordedUser", val);
    },
    pushRecordedStart({ commit }, val) {
      commit("PushRecordedStart", val);
    },
    setRecordedStart({ commit }, val) {
      commit("SetRecordedStart", val);
    },
    pushRecordedEnd({ commit }, val) {
      commit("PushRecordedEnd", val);
    },
    setRecordedEnd({ commit }, val) {
      commit("SetRecordedEnd", val);
    },
    addRecordedCount({ commit }, val) {
      commit("AddRecordedCount", val);
    },
    setRecordedCount({ commit }, val) {
      commit("SetRecordedCount", val);
    },
    updateBlob({ commit }, val) {
      commit("setBlob", val);
    },
    setMediaRecorder({ commit }, val) {
      commit("SetMediaRecorder", val);
    },
    dataaddStartMediaRecorder({ commit }, val) {
      commit("DataaddStartMediaRecorder", val);
    },
    dataaddResumeMediaRecorder({ commit }, val) {
      commit("DataaddResumeMediaRecorder", val);
    },
    startMediaRecorder({ commit }) {
      commit("StartMediaRecorder");
    },
    resumeMediaRecorder({ commit }) {
      commit("ResumeMediaRecorder");
    },
    pauseMediaRecorder({ commit }) {
      commit("PauseMediaRecorder");
    },
    stopMediaRecorder({ commit }) {
      commit("StopMediaRecorder");
    },
    finishFunction({ commit }, val) {
      commit("FinishFunction", val);
    },
    nullifySpeech({ commit }) {
      commit("NullifySpeech");
    },
    setSpeech({ commit }, val) {
      commit("SetSpeech", val);
    },
    startSpeech({ commit }) {
      commit("StartSpeech");
    },
    abortSpeech({ commit }) {
      commit("AbortSpeech");
    },
    addSpeechCount({ commit }) {
      commit("AddSpeechCount");
    },
    finishSpeechCount({ commit }) {
      commit("FinishSpeechCount");
    },
    updateDestroyed({ commit }) {
      commit("setDestroyed");
    },
    openDestroyed({ commit }) {
      commit("OpenDestroyed");
    },
    updateChunks({ commit }, val) {
      commit("UpdateChunks", val);
    },
    setFileName({ commit }, val) {
      commit("SetFileName" , val)
    },
    saveRecordedText({ commit }, val) {
      commit("SaveRecordedText" , val)
    },
    setRecordedUser({ commit }, val) {
      commit("SetRecordedUser" , val)
    },
    setRecordedTime({ commit }, val) {
      commit("SetRecordedTime" , val)
    },
    setTimeRecord({ commit }, val) {
      commit("SetTimeRecord" , val)
    },
    setNameRecord({ commit }, val) {
      commit("SetNameRecord" , val)
    },
    setPlayAudio({ commit }, val) {
      commit("SetPlayAudio" , val)
    },
    setRealname({ commit }, val) {
      commit("SetRealname" , val)
    },
    setIsRealtime({ commit }, val) {
      commit("SetIsRealtime" , val)
    },
    setSelectFile({ commit }, val) {
      commit("SetSelectFile" , val)
    }
  },
  modules: {},
});

export default store